import styles from "./chart.module.css"
import React from "react";
import { useSelector } from "react-redux";

function Chart() {
  const selectedPool = useSelector((state) => state.selectedPool);

  return <iframe title="chart" className={styles.iframe} src={"https://dexscreener.com/bsc/" + selectedPool.chartId + "?embed=1&theme=dark&trades=0&info=0"}></iframe>;
}
export default Chart;

