import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { Web3 } from "web3";
import configAbi from "../constants/ABI/config.json";
import erc20abi from "../constants/ABI/erc20.json";
import abi from "../constants/ABI/pool.json";
import {
  baseTokenAddress,
  baseTokenPoolAddress,
  configAddress,
  pools,
  tokenToAddress,
} from "../constants/contracts";
import { rpcURL } from "../constants/rpcs";
import useExecuteContract from "./useExecuteContract";
import usePosition from "./usePosition";

const usePool = () => {
  const { callContract, sendContract } = useExecuteContract();
  const userAddress = useSelector((state) => state.userAddress);
  const web3wallet = useSelector((state) => state.web3);
  const {
    getSwapPathContract,
    getLiquidityBonusPercentage,
    getLiquidityBonus,
  } = usePosition();

  const getWeb3 = () => {
    return web3wallet ? web3wallet : new Web3(rpcURL);
  };

  const getAllPools = () => {
    return pools;
  };

  const getPriceBINANCE = async (binanceapi) => {
    if (!binanceapi) return { price: 0 };
    try {
      const data = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${binanceapi}`
      );
      const json = await data.json();
      return json.price;
    } catch (err) {
      return { price: 0 };
    }
  };

  const getPriceCMC = async (cmcapi) => {
    if (!cmcapi) return { price: 0 };
    try {
      const data = await fetch(
        `https://apin.niob.app/api/tokenprice?symbol=${cmcapi}`
      );
      const json = await data.json();
      const price = json[cmcapi]["info"];
      return { price: parseFloat(price).toFixed(4) };
    } catch (err) {
      return { price: 0 };
    }
  };

  const getUserPoolBalance = async (poolAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, userPoolBalance] = await callContract({
      contract,
      functionName: "users",
      args: [userAddress],
    });

    return userPoolBalance;
  };

  const getUserPoolData = async (contract, tokenContract, poolAddress) => {
    const [
      userPoolBalance,
      correctedBalance,
      dividendWei,
      userBalanceWei,
      userAllowance,
    ] = await Promise.all([
      callContract({
        contract,
        functionName: "users",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "correctedBalanceOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "dividendsOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract: tokenContract,
        functionName: "balanceOf",
        args: [userAddress],
        getPromise: true,
      }),
      callContract({
        contract: tokenContract,
        functionName: "allowance",
        args: [userAddress, poolAddress],
        getPromise: true,
      }),
    ]);

    const userBalance = new BigNumber(userBalanceWei)
      .dividedBy(1e14)
      .dividedBy(10 ** 4)
      .toFixed(4);
    const userPoolBalanceEth = new BigNumber(userPoolBalance.balance.toString())
      .dividedBy(1e14)
      .dividedBy(10 ** 4)
      .toFixed(4);
    const dividend = new BigNumber(dividendWei)
      .dividedBy(1e12)
      .dividedBy(10 ** 6)
      .toFixed(6);

    let userBalanceFull = new BigNumber(userBalanceWei)
      .dividedBy(1e18)
      .toString();
    let userPoolBalanceFull = new BigNumber(userPoolBalance.balance.toString())
      .dividedBy(1e18)
      .toString();

    if (userPoolBalanceFull.length > 17)
      userPoolBalanceFull = userPoolBalanceFull.substring(0, 17);
    if (userBalanceFull.length > 17)
      userBalanceFull = userBalanceFull.substring(0, 17);

    return {
      userBalance,
      userBalanceFull,
      userPoolBalanceFull,
      userPoolBalance: userPoolBalanceEth,
      isApproved: new BigNumber(1e23).isLessThan(BigNumber(userAllowance)),
      dividend,
      correctedBalance,
    };
  };

  const getUserBalance = async (poolAddress) => {
    if (!web3wallet) return 0;
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);

    const [, userBalance] = await callContract({
      contract: tokenContract,
      functionName: "balanceOf",
      args: [userAddress],
    });

    return userBalance;
  };

  const isApproved = async (poolAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const [, userAllowance] = await callContract({
      contract: tokenContract,
      functionName: "allowance",
      args: [userAddress, poolAddress],
    });

    return new BigNumber(1e23).isLessThan(BigNumber(userAllowance));
  };

  const getPoolData = async (poolAddress) => {
    const pool = pools.find((p) => p.address === poolAddress);
    const tokenAddress = tokenToAddress[pool.title];

    const web3 = getWeb3();
    const contract = new web3.eth.Contract(abi, poolAddress);

    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const asset = pool.title;

    const calls = [
      callContract({
        contract: tokenContract,
        functionName: "balanceOf",
        args: [poolAddress],
        getPromise: true,
      }),
      callContract({
        contract,
        functionName: "outstandingLoans",
        getPromise: true,
      }),
      getInterestRate(tokenAddress),
      getInterestPoolShare(),
    ];

    if (userAddress) {
      calls.push(getUserPoolData(contract, tokenContract, poolAddress));
    }

    if (pool.binanceapi !== "stable") {
      if (pool.title === "NIOB") calls.push(getPriceCMC(pool.cmcapi));
      else calls.push(getPriceBINANCE(pool.binanceapi));
    }

    const callData = await Promise.all(calls);

    const contractBalanceWei = callData[0];
    const loanWei = callData[1];
    const interestRate = parseFloat(callData[2]) / 10;
    const poolShareRate = callData[3];
    const userData = callData[4] || null;
    let price;

    if (pool.title === "NIOB") price = (callData[5] && callData[5].price) || 0;
    else price = callData[5] || 0;

    const loan = web3.utils.fromWei(loanWei.toString(), "ether");
    const contractBalance = web3.utils.fromWei(
      contractBalanceWei.toString(),
      "ether"
    );

    let totalSupply = new BigNumber(loanWei).plus(
      BigNumber(contractBalanceWei)
    );
    const totalSupplyUSD = new BigNumber(totalSupply)
      .multipliedBy(BigNumber(price.toString()))
      .dividedBy(1e18)
      .toFixed(4);

    const loanUSD = new BigNumber(loanWei)
      .multipliedBy(BigNumber(price.toString()))
      .dividedBy(1e18)
      .toFixed(4);

    totalSupply = totalSupply.dividedBy(1e18).toString();

    const apy =
      (loan / totalSupply) *
        ((interestRate * parseFloat(poolShareRate)) / 100) || 0;

    //console.log('title, interestRate, loan/totalSupply, apy, poolShare',pool.title, interestRate, (loan / totalSupply), apy.toFixed(2), parseFloat(poolShareRate) / 100)
    const availableForLeverageUSD = (
      parseFloat(totalSupplyUSD) - parseFloat(loanUSD)
    ).toFixed(4);

    return {
      asset,
      poolAddress: poolAddress,
      tokenAddress,
      price,
      totalSupplyUSD,
      loanUSD,
      loan,
      contractBalance,
      totalSupply,
      userData,
      apy,
      interestRate,
      availableForLeverageUSD,
    };
  };

  const getNewPositionData = async (token, isShort, commitment, leverage) => {
    const fallback = {
      totalSize: 0,
      entryPrice: 0,
      impact: 0,
      liquidationFee: 0,
    };
    if (parseFloat(commitment) <= 0) return fallback;

    const contract = getSwapPathContract();
    const web3 = getWeb3();
    const convertedTokenAddress = tokenToAddress[token];

    let totalSize = parseFloat(commitment) * parseFloat(leverage);
    let liquidationFee = await getLiquidityBonus();
    const liquidityBonusPercentage = await getLiquidityBonusPercentage();
    let liquidationFeeTotal =
      (totalSize * BigNumber(liquidityBonusPercentage).toNumber()) / 1000 +
      BigNumber(liquidationFee).toNumber();
    const amount = web3.utils.toWei(totalSize.toString(), "ether");
    const oneEth = "1000000000000000000";

    //long position
    if (!isShort) {
      const [resultSwap, resultOneEth] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, amount],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const convertedTotalSize = web3.utils.fromWei(resultSwap, "ether");
      const convertedOneEthSize = web3.utils.fromWei(resultOneEth, "ether");
      const entryPrice = totalSize / convertedTotalSize;
      const oneEthPrice = 1 / convertedOneEthSize;

      let impact = ((entryPrice / oneEthPrice - 1) * 100).toFixed(3);

      if (impact < 0) impact = 0;
      return {
        totalSize,
        entryPrice,
        impact,
        liquidationFee: liquidationFeeTotal,
      };
    }
    //short position
    else {
      const [resultSwap, resultOneEth] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, amount],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [baseTokenAddress, convertedTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const [resultSwapRev, resultOneEthRev] = await Promise.all([
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [convertedTokenAddress, baseTokenAddress, resultSwap],
          getPromise: true,
        }),
        callContract({
          contract,
          functionName: "calculateConvertedValue",
          args: [convertedTokenAddress, baseTokenAddress, oneEth],
          getPromise: true,
        }),
      ]);

      const convertedTotalSize = web3.utils.fromWei(resultSwap, "ether");
      //const convertedOneEthSize = web3.utils.fromWei(resultOneEth, 'ether');
      //const entryPrice = totalSize / convertedTotalSize;
      //const oneEthPrice = 1 / convertedOneEthSize;

      const convertedTotalSizeRev = web3.utils.fromWei(resultSwapRev, "ether");
      const convertedOneEthSizeRev = web3.utils.fromWei(
        resultOneEthRev,
        "ether"
      );
      const entryPriceRev = convertedTotalSize / convertedTotalSizeRev;
      const oneEthPriceRev = 1 / convertedOneEthSizeRev;

      let impact = parseInt((entryPriceRev / oneEthPriceRev - 1) * 100);
      if (impact < 0) impact = 0;

      return {
        totalSize: convertedTotalSize,
        entryPrice: 1 / entryPriceRev,
        impact,
        liquidationFee: liquidationFeeTotal,
      };
    }
  };

  const getAvailableSupply = async (token, isShort) => {
    const tokenAddress = isShort ? tokenToAddress[token] : baseTokenAddress;
    const poolAddress = isShort
      ? pools.find((p) => p.title === token).address
      : baseTokenPoolAddress;
    const web3 = getWeb3();
    const tokenContract = new web3.eth.Contract(erc20abi, tokenAddress);
    const [_, availableSupplyWei] = await callContract({
      contract: tokenContract,
      functionName: "balanceOf",
      args: [poolAddress],
    });

    //return availableSupplyWei;
    return new BigNumber(availableSupplyWei).dividedBy(1e18).toFixed(3);
  };

  const deposit = async (poolAddress, amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString(), "ether");
    return await sendContract(contract, {
      functionName: "deposit",
      arguments: [amountWei],
    });
  };

  const withdraw = async (poolAddress, amount) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const amountWei = web3wallet.utils.toWei(amount.toString(), "ether");
    return await sendContract(contract, {
      functionName: "withdraw",
      arguments: [amountWei],
    });
  };

  const approveToken = async (poolAddress) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    const [, tokenAddress] = await callContract({
      contract,
      functionName: "token",
    });

    const tokenContract = new web3wallet.eth.Contract(erc20abi, tokenAddress);
    return await sendContract(tokenContract, {
      functionName: "approve",
      arguments: [poolAddress, "99999999999999999999999999999"],
    });
  };

  const claimReward = async (poolAddress) => {
    if (!web3wallet) return null;
    const contract = new web3wallet.eth.Contract(abi, poolAddress);
    return await sendContract(contract, {
      functionName: "claim",
    });
  };

  const getMaxLeverage = async (token) => {
    const web3 = getWeb3();
    const tokenAddress = tokenToAddress[token];
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      return await contract.methods.getMaxLeverage(tokenAddress).call();
    } catch {
      return 5;
    }
  };

  const getInterestPoolShare = async () => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      return await contract.methods.poolFeeShare().call();
    } catch {
      return 66;
    }
  };

  const getInterestRate = async (tokenAddress) => {
    const web3 = getWeb3();
    const contract = new web3.eth.Contract(configAbi, configAddress);
    try {
      return await contract.methods.getInterestRate(tokenAddress).call();
    } catch (err) {
      console.log(err);
      return 30;
    }
  };

  return {
    getAllPools,
    getPoolData,
    getNewPositionData,
    getAvailableSupply,
    deposit,
    withdraw,
    approveToken,
    isApproved,
    getUserBalance,
    claimReward,
    getUserPoolBalance,
    getMaxLeverage,
    getInterestRate,
  };
};

export default usePool;
