import { useEffect, useState } from 'react'
import classNames from "classnames";
import styles from "./markets-header.module.css";
import { numberWithCommas } from "utils/libs";
import { useTranslation } from "react-i18next";
import projectConfig from '_customization/project-config'
import { handleSetLanguageStyle } from 'utils/shared-methods'


function MarketsHeader({ marketsData }) {
  const { t } = useTranslation();
  const [translatedLangStyle, setTranslatedLangStyle] = useState('');

  useEffect(() => {
    handleSetLanguageStyle("tr", setTranslatedLangStyle);
  }, [window.localStorage.getItem('i18nextLng')]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className="whiteColor">{t('markets-header.Markets_text')}</div>
        <div className={classNames(styles.detail, "whiteColor")}>
          {!translatedLangStyle.length ? (
              `${t('markets-header.paragraph_object.text1')} ${projectConfig.appName} ${t('markets-header.paragraph_object.text2')}`
          ) : (
              `${projectConfig.appName}${t('markets-header.paragraph_object.text2')}`
          )}

        </div>
        <div className={styles.row1}>
          <div className={styles.col1}>
            <div>{t('markets-header.Trading_text')}</div>
            <div className={styles.volume}>
              $
              {marketsData?.volume &&
                numberWithCommas(Number(marketsData?.volume).toFixed(2))}
            </div>
            <div>{t('markets-header.exchange_paragraph_text')}</div>
          </div>
          <div className={classNames("verticalSeparator", styles.sp)}></div>
          <div className={styles.col1}>
            <div>{t('markets-header.OpenInterest_text')}</div>
            <div className={styles.volume}>
              ${numberWithCommas(marketsData?.openInterest?.toFixed(2))}
            </div>
            <div>
              {!translatedLangStyle.length ? (
                  `${t('markets-header.OpenPositionPara_object.text1')} ${projectConfig.appName} ${t('markets-header.OpenPositionPara_object.text2')}`
              ) : (
                  `${projectConfig.appName}${t('markets-header.OpenPositionPara_object.text2')}`
              )}
            </div>
          </div>
          <div
            className={classNames("verticalSeparator", styles.sp, styles.sp2)}
          ></div>
          <div className={styles.col1}>
            <div>{t('markets-header.Trades_text')}</div>
            <div className={styles.volume}>
              {numberWithCommas(marketsData?.numPositions)}
            </div>
            <div>{t('markets-header.executedpara_text')}</div>
          </div>
          <div className={classNames("verticalSeparator", styles.sp)}></div>
          <div className={styles.col1}>
            <div>{t('markets-header.TopEarning_text')}</div>
            <div className={styles.volume}>
              {marketsData?.topAPY?.toFixed(2)}%
            </div>
            <div>{marketsData?.topToken}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketsHeader;
