import React, { useEffect, useState } from 'react'
import styles from './load.module.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import projectConfig from '_customization/project-config'
import { handleSetLanguageStyle } from 'utils/shared-methods'

function Load({ modal }) {
  const { t } = useTranslation();
  const [translatedLangStyle, setTranslatedLangStyle] = useState('');

  useEffect(() => {
    handleSetLanguageStyle("tr", setTranslatedLangStyle)
  }, [window.localStorage.getItem('i18nextLng')])

  const accept = () => {
    modal(false);
    localStorage.setItem("ShowDisclaimer", false);
  }
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.aboveTitle}>{projectConfig.appName} {t("loadEvent.appVersion")}</div>
        <div className={styles.title}>{t("loadEvent.disclaimer")}</div>
        {!translatedLangStyle.length ? (
            <div className={styles.body}>
              {t("loadEvent.warning_object.text1")}
              {" " + projectConfig.appName}
              {t("loadEvent.warning_object.text2")}
              {" " + projectConfig.companyName + " "}
              {t("loadEvent.warning_object.text3")}
              {" " + projectConfig.companyName + " "}
              {t("loadEvent.warning_object.text4")}
              {" " + projectConfig.appName}
              {t("loadEvent.warning_object.text5")}
              {" " + projectConfig.appName}
              {t("loadEvent.warning_object.text6")}
              <br /><br />
            </div>
        ) : (
            <div className={styles.body}>
              {t("loadEvent.warning_object.text1")}
              {" " + projectConfig.appName + " "}
              {t("loadEvent.warning_object.text2")}
              {" " + projectConfig.companyName}
              {t("loadEvent.warning_object.text3")}
              {" " + projectConfig.companyName}
              {t("loadEvent.warning_object.text4")}
              {" " + projectConfig.appName}
              {t("loadEvent.warning_object.text5")}
              {" " + projectConfig.appName}
              {t("loadEvent.warning_object.text6")}
              <br /><br />
            </div>
        )}
        <span className={styles.bodySpan}>{t("loadEvent.contact_text")} {projectConfig.supportEmail}. {t("loadEvent.appreciateSupport_text")}</span>
        <div className={styles.footer}>
          <button onClick={accept} className={styles.btnAccept}>
            <div className={styles.btnText}>{t("loadEvent.accept")}</div>
          </button>
          <button className={styles.btnDecline}>
            <a target="_blank" href={projectConfig.externalLinks.website}>
              <div className={styles.btnText}>{t("loadEvent.decline")}</div>
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Load