import React from "react";
import { useState } from "react";
import styles from "./TopHeader.module.css";
import { ReactComponent as CloseIcon } from "assets/xmark.svg";
import logo from "_customization/assets/top-header.png";
import { Link } from "react-router-dom";
import pathNames from "utils/path-names";
import { useTranslation } from "react-i18next";
import projectConfig from '_customization/project-config'

function TopHeader() {
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(true);

  return (
    <div>
      {hidden && (
        <div className={styles.container}>
          <img src={logo} className={styles.logo} alt={ projectConfig.appName + ' icon' } />

          <div className={styles.text1}>
            <div className={styles.subText}>
              {t("header.WelcometotheBetaof_text")}{" "}
              <b> {projectConfig.appName} </b> -
            </div>
            <div className={styles.subText}>
              {t("header.Fullydecentralizedleveragetradingand_text")}
            </div>
            <div className={styles.subText}>
              {" "}
              <Link to={pathNames.lending}>
                <u>{t("header.Earning_text")}</u>
              </Link>{" "}
              {t("header.protocol")}
            </div>
          </div>

          <CloseIcon
            className={styles.close}
            fill="#ffffff"
            onClick={() => setHidden(false)}
          />
        </div>
      )}
    </div>
  );
}

export default TopHeader;
