import eth from "assets/eth.svg"
import USDT from "assets/tether.png"
import BNB from "assets/bnb.png"
import BTC from "assets/bitcoin.png"
import USDC from "assets/usdc.svg"
import coin from "assets/coin.png"
import link from "assets/link.svg"
import cake from "assets/cake.svg"
import projectIcon from "_customization/assets/token-icon.png"
import ada from "assets/ada.svg"
import dai from "assets/dai.svg"
import matic from "assets/matic.svg"
import trx from "assets/trx.svg"
import dot from "assets/dot.svg"

function TokenIcon({ tokenName, className }) {
    let src;
    switch (tokenName) {
        case "ETH":
            src = eth;
            break;
        case "WBNB":
            src = BNB;
            break;
        case "BNB":
            src = BNB;
            break;
        case "BTC":
            src = BTC;
            break;
        case "BTCB":
            src = BTC;
            break;
        case "USDT":
            src = USDT;
            break;
        case "USDC":
            src = USDC;
            break;
        case "LINK":
            src = link;
            break;
        case "CAKE":
            src = cake;
            break;
        case "NIOB":
            src = projectIcon;
            break;
        case "ADA":
            src = ada;
            break;
        case "DAI":
            src = dai;
            break;
        case "MATIC":
            src = matic;
            break;
        case "TRX":
            src = trx;
            break;
        case "DOT":
            src = dot;
            break;
        default:
            src = coin;
            break;

    }
    return (<img src={src} className={className} alt={tokenName + ' icon'} />)
}
export default TokenIcon;