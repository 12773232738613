import { rpcURL } from "../constants/rpcs";
import { marginAddress, tokens, pools } from "../constants/contracts";
import marginAbi from "../constants/ABI/margin.json";
import { Web3 } from "web3";
import useExecuteContract from "./useExecuteContract";
import usePrice from "./usePrice";
import usePool from "./usePool";
import { useSelector } from "react-redux";
import BigNumber from 'bignumber.js';
import projectConfig from '_customization/project-config'

const useStats = () => {
  const { callContract } = useExecuteContract();
  const { getPriceChange } = usePrice();
  const { getPoolData } = usePool();
  const userAddress = useSelector((state) => state.userAddress);

  const getVolume = async () => {
    try {
      const volumeData = await fetch(projectConfig.api.getVolumeData);
      const data = await volumeData.json();
      return new BigNumber(data.result.toString()).dividedBy(10**18).toFixed(2);
    } catch (err) {
      return 0;
    }
  };

  const getNumPositions = async () => {
    try {
      const volumeData = await fetch(projectConfig.api.getNumPositions);
      const data = await volumeData.json();

      return data.result;
    } catch (err) {
      return 0;
    }
  };

  const getTotalFees = async () => {
    if(!userAddress) return 0;
    try {
      const volumeData = await fetch(projectConfig.api.getTotalFees + userAddress);
      const data = await volumeData.json();
      return data.result;
    } catch{
      return 0;
    }
  };

  const getOpenInterests = async () => {
    const positionsData = await fetch(projectConfig.api.getOpenPositions);
    const data = await positionsData.json();

    const calls = [];

    const web3 = new Web3(rpcURL);
    const contract = new web3.eth.Contract(marginAbi, marginAddress);

    data.result.forEach((position) => {
      calls.push(
        callContract({
          contract: contract,
          functionName: "calculateBorrowInterest",
          args: [position.positionId],
          getPromise: true,
        })
      );
    });

    const interests = await Promise.all(calls);

    const result = {
      totalInterestUSD: 0,
      tokenInterests: {},
    };
    const tokenPrices = {};

    for (let i = 0; i < data.result.length; i++) {
      const position = data.result[i];
      const token = Web3.utils.toChecksumAddress(position.token);
      const tokenName = position.isShort ? tokens[token].title : 'USDC';
      if (!tokenPrices[tokenName]) {
        const priceData = await getPriceChange(tokenName);
        tokenPrices[tokenName] = parseFloat(priceData.currentPrice);
      }
      const interestAmount = parseFloat(web3.utils.fromWei(interests[i], 'ether'));
      const interestAmountUSD = interestAmount * tokenPrices[tokenName];

      if (!result.tokenInterests[tokenName])
        result.tokenInterests[tokenName] = { amount: 0, amountUSD: 0 };
      result.tokenInterests[tokenName].amount += interestAmount;
      result.tokenInterests[tokenName].amountUSD += interestAmountUSD;
      result.totalInterestUSD += interestAmountUSD;
    }

    return result;
  };

  const getMarketsData = async () => {
    const [openInterests, numPositions, volume] = await Promise.all([
      getOpenInterests(),
      getNumPositions(),
      getVolume(),
    ]);

    let topAPY = 0;
    let topToken;

    const marketPools = [];
    for (let i = 0; i < pools.length; i++) {
      const pool = pools[i];
      const tokenName = pool.title;
      const priceData = await getPriceChange(tokenName);
      let openInterestToken = 0;
      let openInterestUSD = 0;
      if (openInterests.tokenInterests[tokenName]) {
        openInterestToken = openInterests.tokenInterests[tokenName].amount;
        openInterestUSD = openInterests.tokenInterests[tokenName].amountUSD;
      }
      const funding = 0;

      const poolData = await getPoolData(pool.address);
      const apy = poolData.apy;

      if(parseFloat(apy) >= topAPY){
        topAPY = parseFloat(apy);
        topToken = tokenName;
      }

      const dailyInterest = (parseFloat(poolData.interestRate) / 365).toFixed(2);

      const poolInfo = {
        token: tokenName,
        ...priceData,
        openInterestToken,
        openInterestUSD,
        funding,
        apy,
        dailyInterest,
      };
      marketPools.push(poolInfo);
    }


    return {
      volume,
      openInterest: openInterests.totalInterestUSD,
      numPositions,
      topAPY,
      topToken,
      marketPools,
    };
  };

  return {
    getVolume,
    getTotalFees,
    getOpenInterests,
    getNumPositions,
    getMarketsData,
  };
};

export default useStats;
