import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styles from './message.module.css';
import { ReactComponent as XmarkIcon } from 'assets/xmark.svg';
import { ReactComponent as CheckIcon } from 'assets/circle-check-solid.svg';
import { useTranslation } from 'react-i18next'
function Message() {
    const { t } = useTranslation();
    const showMessage = useSelector((state) => state.showMessage);
    const txlink = useSelector((state) => state.txlink);
    const messageTitle = useSelector((state) => state.messageTitle);

    const dispatch = useDispatch();
    function dismiss() {
        dispatch({ type: "showMessageChange", showMessage: false });
    }
    const timer = setTimeout(() => {
        if (showMessage) {
            dismiss();
        }
    }, 8000);
    return <div className={classNames(styles.container, showMessage && styles.show)}>
        <div className={styles.c1}>
            <CheckIcon className={styles.icon1} />
        </div>
        <div>
            <div className={styles.content}>{messageTitle}</div>
            <div className={styles.link}>
                <a href={'https://bscscan.com/tx/' + txlink} target="_blank">{t("transactionPopups.ViewOn_text")} BscScan</a>
            </div>
        </div>
        <XmarkIcon className={styles.icon2} onClick={dismiss} />
    </div>
}
export default Message;
