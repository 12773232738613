export default {
  /*The files should be manually changed ("NIOB"):
   * 1) contract.js
   * 2) config.json
   * 3) usePrice, useSwap, useBalance, usePool
   * 4) select-token.js, lending.js, open-position.js
   * 5) tokenIcon.js
   * */

  appName: "Niob" /*Important!: "Niob" or "AnchorExchange"*/,
  tokenName: "NIOB",
  parentWebsiteName: "NIOB",
  supportEmail: "social@niob.finance",
  companyName: "Chiffre IT Solutions",

  configs: {
    topHeader: false /*true | false*/,
    tokenHold: false /*true | false*/,
  },

  externalLinks: {
    website: "https://niob.finance/",
    docs: "https://docs.niob.finance/",
    docsTutorial: "https://docs.niob.finance/tutorial/",
    terms: "https://niob.finance/terms/",
    dataPrivacy: "https://niob.finance/data-privacy/",
    refRegisterPage: "https://dashboard.niob.io/",

    /*To modify: change link to '' or null*/
    telegram: "https://t.me/niobofficial",
    twitter: "https://twitter.com/niobfinance",
    medium: "https://niobfinance.medium.com/",
    chart:
      "https://www.dextools.io/app/en/bnb/pair-explorer/0xb9f3a44ca2d0d8d2a74751476d1a984b04724824",
  },

  api: {
    getVolumeData:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/volume24",
    getNumPositions:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/positions24",
    getTotalFees:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserFee?user=",
    getOpenPositions:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getOpenPositions",
    getUserOpenPositions:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserOpenPositions?user=",
    getUserClosedPositions:
      "https://f1yx7ajp2bfl.grandmoralis.com:2053/server/functions/getUserClosedPositions?user=",
    getCurrentTokenPrice:
      "https://apin.niob.app/api/tokenactprice?symbol=NIOB",
    getRefShare: "https://apidev.anchorlink.io/api/wallet/refshare/get?wallet=",
    postNewRefShare: "https://apidev.anchorlink.io/api/wallet/refshare",
    getRefLinkId: "https://apidev.anchorlink.io/api/user/verifyid?wallet=",
    getWalletSponsor:
      "https://apidev.anchorlink.io/api/user/verifysponsorid?wallet=",
    getCurrentTokenChart:
      "https://apin.niob.app/api/tokenprice?symbol=NIOB&interval=",
  },
};
