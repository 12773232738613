import { useTranslation } from 'react-i18next'
import useReferrals from 'hooks/useReferrals'
import { useSelector } from 'react-redux'
import { removeNewLinesOnString } from 'utils/shared-methods'
import pathDependentLinks from 'utils/path-dependent-links'
import projectConfig from '_customization/project-config'

const ManageReferralStates = () => {
    const { t } = useTranslation();
    const { verifyWalletRefId } = useReferrals();
    const userAddress = useSelector((state) => state.userAddress);

    //mode: "sidebar" or "page"
    const getRefIdCallData = (mode, { setRefIdCallObject, setRefLink, setRefLinkText, setAdditLink }) => {
        if (userAddress) {
            verifyWalletRefId().then((res) => {
                if (res) {
                    setRefIdCallObject(res);
                }
            });
        } else {
            setRefLink('');

            let text = t("referralOverview.noRefLink_text");
            if (mode === "page") {
                text = t("referralOverview.connectWallet_text")
            }
            setRefLinkText(text);

            if (setAdditLink) setAdditLink('');
        }
    }

    //mode: "sidebar" or "page"
    const setRefIdCallData = (mode, { refIdCallObject, setRefLink, setRefLinkText, setAdditLink }) => {
        if (!refIdCallObject) return

        if (refIdCallObject.success) {
            const linkFormatted = removeNewLinesOnString(pathDependentLinks.refLinkSkeleton + refIdCallObject.info);
            setRefLink(linkFormatted);

            let text = t("referralOverview.referralLink_text")
            if (mode === "page") {
                text = linkFormatted
            }
            setRefLinkText(text);

            if (setAdditLink) setAdditLink('');
        } else {
            let text = t("referralOverview.noRefLink_text");
            if (mode === "page") {
                text = t("referralOverview.registerToRefSystem") + ' ';
            }

            setRefLinkText(text);

            if (setAdditLink) setAdditLink(projectConfig.externalLinks.refRegisterPage);
        }
    }

    return  {
        setRefIdCallData,
        getRefIdCallData
    };
};

export default ManageReferralStates;


