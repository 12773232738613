import { useSelector } from "react-redux";
import projectConfig from '_customization/project-config'


const useReferrals = () => {
  const userAddress = useSelector((state) => state.userAddress);

  const addNewRefShare = async (wallet) => {
    const refID = window.localStorage.getItem('refID');

    if (refID !== undefined && refID !== '' && refID !== 'null') {
      const data = new FormData();
      data.append('wallet', wallet);
      data.append('wallet_sponsor', refID);
      data.append('project', projectConfig.appName.toLowerCase());
      const requestOptions = {
        method: 'POST',
        headers: {'APIKey': 'NoLogin4U'},
        body: data
      };
      await fetch(projectConfig.api.postNewRefShare, requestOptions)
          .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();

            // check for error response
            if (response.success !== 1) {
              // get error message from body or default to response status
              //const error = (data && data.message) || response.status;
              return 0;
            }
          })
          .catch(error => {
            //console.error('There was an error!', error);
            return 0;
          });
    }
  }

  const getRefShare = async () => {
      const verifyWalletData = await verifyWalletRefId()
      if(!verifyWalletData.success) return 0;
      try {
          const refShareData = await fetch(
              projectConfig.api.getRefShare + verifyWalletData.info + "&project=" + projectConfig.appName.toLowerCase(), {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/json', 'APIKey': 'NoLogin4U' }
              }
          );
          const data = await refShareData.json();
          return data;
      } catch (err) {
          return 0;
      }
  };

  const verifyWalletRefId = async () => {
      if(!userAddress) return 0;
      try {
          const verifyWalletData = await fetch(
              projectConfig.api.getRefLinkId + userAddress + "&project=" + projectConfig.appName.toLowerCase(), {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/json', 'APIKey': 'NoLogin4U' }
              }
          );
          const data = await verifyWalletData.json();
          return data;
      } catch (err) {
          return 0;
      }
  };

  const getUserSponsor = async () => {
      if(!userAddress) return 0;
      try {
          const walletSponsor = await fetch(
              projectConfig.api.getWalletSponsor + userAddress + "&project=" + projectConfig.appName.toLowerCase(), {
                  method: 'GET',
                  headers: { 'Content-Type': 'application/json', 'APIKey': 'NoLogin4U' }
              }
          );
          const data = await walletSponsor.json();
          return data;
      } catch (err) {
          return 0;
      }
  };

  return {
      getRefShare,
      addNewRefShare,
      verifyWalletRefId,
      getUserSponsor,
  };
};

export default useReferrals;
