import usePool from "./usePool";
import useSwap from "./useSwap";
import { pools, masterchef } from "../constants/contracts";
import BigNumber from "bignumber.js";
import masterChefAbi from "../constants/ABI/masterchef.json";
import { Web3 } from "web3";
import { rpcURL } from "constants/rpcs";
import { useSelector } from "react-redux";

const tokenName = "NIOB";
const anchorPoolId = 2;

const useBalance = () => {
  const userAddress = useSelector((state) => state.userAddress);
  const { getTokenBalance } = useSwap();
  const { getUserPoolBalance } = usePool();

  const getAnchorBalance = async () => {
    const walletBalance = await getTokenBalance(tokenName, true);
    const pool = pools.find((p) => p.title === tokenName);
    const levPoolData = await getUserPoolBalance(pool.address);
    const poolBalance = levPoolData.balance || '0';
    let masterchefBalance = 0;

    try {
      const web3 = new Web3(rpcURL);
      const masterchefContract = new web3.eth.Contract(
        masterChefAbi,
        masterchef
      );
      const masterchefData = await masterchefContract.methods.userInfo(
        anchorPoolId,
        userAddress
      ).call();
      masterchefBalance = masterchefData.amount;
    } catch(err) {console.log(err)}

    return new BigNumber(walletBalance)
      .plus(poolBalance)
      .plus(masterchefBalance)
      .dividedBy(1e18)
      .toFixed(2);
  };

  return { getAnchorBalance };
};

export default useBalance;
