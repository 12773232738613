module.exports = {
  tokens: {
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8": {
      title: "ETH",
      binanceapi: "ETHUSDT",
    },
    "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06": {
      title: "LINK",
      binanceapi: "ETHUSDT",
    },
    "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd": {
      title: "WBNB",
      binanceapi: "BNBUSDT",
    },
    "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd": {
      title: "USDT",
      binanceapi: "stable",
    },
  },
  tokenToAddress: {
    NIOB: "0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b", //TOKEN ADDY
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    USDC: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    BTCB: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    CAKE: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  marginAddress: "0x94d9360dfa5704c9423E7610EA683e96A193E9bD",
  swapPathCreatorAddress: "0x31335D77508276c40B9a44a845D28E7d99207964",
  configAddress: "0x226D7bFDd6CeF0055FF6990144894607cDdbE260",
  baseTokenAddress: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  baseTokenPoolAddress: "0xE265385f2b51f71Da0833DbDe5277b8a67fdA067",
  exchangeFactory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  swapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  masterchef: "0xD18B23ad6c8ACc4AD32AAd6a5dF750ce28C8C772", //TOKEN MASTERCHEF
  pools: [
    {
      title: "NIOB",
      address: "0x3f7D256a8B1142892bD8C6061E8D41834926d757",
      tokenAddress: "0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b",
      chartSymbol: "NIOBUSDT",
      binanceapi: "NIOBUSDT",
      cmcapi: "NIOB",
      chartId: "0x6eccf113ad5ffdc04b324f8d7306797ef164b8b8",
    },
    {
      title: "USDC",
      address: "0x00Cd67508b967F45149564f2C8D7AfbD2F665B7d", //0xE265385f2b51f71Da0833DbDe5277b8a67fdA067
      tokenAddress: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      chartSymbol: "USDCUSDT",
      binanceapi: "USDCUSDT",
      chartId: "0x92b7807bf19b7dddf89b706143896d05228f3121",
    },
    {
      title: "WBNB",
      address: "0xbc56200CfacE765EA6a84DcfD5b1b36Ebb67aFAd",
      tokenAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      chartSymbol: "WBNBUSDC",
      binanceapi: "BNBUSDC",
      chartId: "0xd99c7f6c65857ac913a8f880a4cb84032ab2fc5b",
    },
    {
      title: "ETH",
      address: "0xaeA68c5A7eD5C959E2d08cE3c622739c2cA23b9C",
      tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      chartSymbol: "WBNBUSDT",
      binanceapi: "ETHUSDT",
      chartId: "0x7213a321f1855cf1779f42c0cd85d3d95291d34c",
    },
    {
      title: "BTCB",
      address: "0x940d3D65B7b24efCA9ECB5f57A128f0EA9E54c45",
      tokenAddress: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      chartSymbol: "BTCBUSDT",
      binanceapi: "BTCUSDT",
      chartId: "0xf45cd219aef8618a92baa7ad848364a158a24f33",
    },
    {
      title: "USDT",
      address: "0xa91223176Da8985f47ae0aF2A3f36e81cA109B68",
      tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
      chartSymbol: "USDCUSDT",
      binanceapi: "USDCUSDT",
      chartId: "0x92b7807bf19b7dddf89b706143896d05228f3121",
    },
    {
      title: "CAKE",
      address: "0x23B6094Ff3B82bd636cB8381b81aC60eA9C03684",
      tokenAddress: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      chartSymbol: "CAKEUSDT",
      binanceapi: "CAKEUSDT",
      chartId: "0x804678fa97d91b974ec2af3c843270886528a9e6",
    },
  ],
  swapTokens: {
    BNB: {
      token: "BNB",
      binanceId: "BNBUSDT",
      geckoId: "wbnb",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    }, //wbnb address
    NIOB: {
      token: "NIOB",
      binanceId: "niob",
      geckoId: "niob",
      address: "0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b",
    },
    USDC: {
      token: "USDC",
      binanceId: "USDCUSDT",
      geckoId: "usd-coin",
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    ETH: {
      token: "ETH",
      binanceId: "ETHUSDT",
      geckoId: "ethereum",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    },
    ADA: {
      token: "ADA",
      binanceId: "ADAUSDT",
      geckoId: "cardano",
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    },
    MATIC: {
      token: "MATIC",
      binanceId: "MATICUSDT",
      geckoId: "matic-network",
      address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    },
    DAI: {
      token: "DAI",
      binanceId: "DAIUSDT",
      geckoId: "dai",
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    },
    BTCB: {
      token: "BTCB",
      geckoId: "bitcoin",
      binanceId: "BTCUSDT",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    },
    USDT: {
      token: "USDT",
      binanceId: "stable",
      geckoId: "tether",
      address: "0x55d398326f99059fF775485246999027B3197955",
    },
    DOT: {
      token: "DOT",
      binanceId: "DOTUSDT",
      geckoId: "polkadot",
      address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    },
    TRX: {
      token: "TRX",
      binanceId: "TRXUSDT",
      geckoId: "tron",
      address: "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B",
    },
    CAKE: {
      token: "CAKE",
      binanceId: "CAKEUSDT",
      geckoId: "pancakeswap-token",
      address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    },
    WBNB: {
      token: "WBNB",
      binanceId: "BNBUSDT",
      geckoId: "wbnb",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
  },
};
