import classNames from 'classnames';
import Positions from 'components/positions/positions';
import usePosition from 'hooks/usePosition';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './positions-and-history.module.css';
import loading from '_customization/assets/loading.gif';
import { useTranslation } from 'react-i18next';
import PageName from 'components/_shared/page-name/PageName';
import { handleSetLanguageStyle } from 'utils/shared-methods';

function PositionsAndHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getUserOpenPositions, getUserClosedPositions, getPositionData, closePosition } = usePosition();
  const positionsUpdate = useSelector((state) => state.positionsUpdate);
  const periodicUpdate = useSelector((state) => state.periodicUpdate);
  
  const [openPositions, setOpenPositions] = useState([]);
  const [closedPositions, setClosedPositions] = useState([]);
  const userAddress = useSelector((state) => state.userAddress);
  const [inprogress1, setInprogress1] = useState(false);
  const [inprogress2, setInprogress2] = useState(false);
  const tabs = { positions: 1, history: 2 };
  const [selectedTab, setSelectedTab] = useState(tabs.positions);
  
  const [translatedEnStyle, setTranslatedEnStyle] = useState('');
  
  useEffect(() => {
    handleSetLanguageStyle('en', setTranslatedEnStyle);
  }, [window.localStorage.getItem('i18nextLng')]);
  
  useEffect(() => {
    getUserOpenPositions().then(async (res) => {
      if (Array.isArray(res)) {
        const lst = [];
        for (let ix = 0; ix < res.length; ix++) {
          let position = await getPositionData(res[ix]);
          if (position)
            lst.push(position);
        }
        setOpenPositions(lst);
      }
    });
  }, [periodicUpdate]);
  
  useEffect(() => {
    setOpenPositions([]);
    setClosedPositions([]);
    
    setInprogress1(true);
    getUserOpenPositions().then(async (res) => {
      if (Array.isArray(res)) {
        const lst = [];
        for (let ix = 0; ix < res.length; ix++) {
          let position = await getPositionData(res[ix]);
          if (position)
            lst.push(position);
        }
        setOpenPositions(lst);
      }
      setInprogress1(false);
    });
    
    setInprogress2(true);
    getUserClosedPositions().then(async (res) => {
      setInprogress2(false);
      if (Array.isArray(res)) {
        setClosedPositions(res);
      }
    });
    
  }, [userAddress, positionsUpdate]);
  
  function close(position, complete) {
    closePosition(position.positionId).then((res) => {
      if (res.status === true)
        dispatch({
          type: 'showMessageChange',
          showMessage: true,
          txlink: res.txlink,
          messageTitle: t('transactionPopups.Closed_text')
        });
      complete();
      dispatch({ type: 'positionsUpdateChange' });
    });
  }
  
  function onSelectTabChange(tab) {
    setSelectedTab(tab);
  }
  
  return <div className={styles.container}>
    <PageName
      title={t('Positions&History.Position_text')}
      titleLong={t('Positions&History.Positions&his_text')}
      text={t('Positions&History.navigate_text')}
    />
    <div className={styles.tabTitles}>
      <div onClick={() => onSelectTabChange(tabs.positions)}
           className={classNames(selectedTab === tabs.positions && styles.tabTitleSelected)}>{t('Positions&History.urPositions_text')}</div>
      <div onClick={() => onSelectTabChange(tabs.history)}
           className={classNames(selectedTab === tabs.history && styles.tabTitleSelected)}>{t('Positions&History.tradeHistory_text')}</div>
    </div>
    <div
      className={classNames(styles.tabLine, selectedTab === tabs.history && styles[`tab2Selected${translatedEnStyle}`])}>
    </div>
    <div className={styles.title}>
      {selectedTab === tabs.positions && t('Positions&History.openedPositons_text')}
      {selectedTab === tabs.history && t('Positions&History.tradingHistory_text')}
    </div>
    {selectedTab === tabs.positions && <>
      {inprogress1 && <div className={styles.loadingParent}>
        <img className={styles.loading} src={loading} alt="loading spinner"/>
      </div>}
      <Positions positions={openPositions} close={close}/>
      {/*<div className={styles.emptyList}>You currently have no trade history</div>*/}
    </>}
    {selectedTab === tabs.history && <>
      {inprogress2 && <div className={styles.loadingParent}>
        <img className={styles.loading} src={loading} alt="loading spinner"/>
      </div>}
      <Positions positions={closedPositions}/>
      {/*<div className={styles.emptyList}>You currently have no trade history</div>*/}
    </>}
  </div>;
}

export default PositionsAndHistory;