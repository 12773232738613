// checks page language and returns '' or 'Translated'
const handleSetTranslatedStyle = (setTranslatedStyle) => {
    const lang = window.localStorage.getItem('i18nextLng')

    if (lang && lang !== 'en') {
        setTranslatedStyle("Translated")
    } else {
        setTranslatedStyle('')
    }
}

//focusedLanguage: "de", "tr", "en"
const handleSetLanguageStyle = (focusedLanguage, setTranslatedLangStyle) => {
    const lang = window.localStorage.getItem('i18nextLng')

    if (!lang) return;

    if (focusedLanguage === lang) {
        setTranslatedLangStyle(focusedLanguage)
    } else {
        setTranslatedLangStyle('')
    }

}

const removeNewLinesOnString = (link) => {
    return link.replace(/(\r\n|\n|\r)/gm, "")
}

export {
    removeNewLinesOnString,
    handleSetLanguageStyle,
    handleSetTranslatedStyle
}