import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import erc20ABI from '../constants/ABI/erc20.json';
import wbnbABI from '../constants/ABI/wbnb.json';
import routerABI from '../constants/ABI/router.json';
import exchangeFactoryABI from '../constants/ABI/exchangeFactory.json';
import exchangePairABI from '../constants/ABI/exchangePair.json';
import { exchangeFactory, swapRouter, swapTokens, tokenToAddress } from '../constants/contracts';
import { Web3 } from 'web3';

const useSwap = () => {
  const userAddress = useSelector((state) => state.userAddress);
  const web3wallet = useSelector((state) => state.web3);
  const slippagePercentage = useSelector((state) => state.slippagePercentage);

  const isNativeToken = (tokenName) => {
    return tokenName === "BNB";
  };

  const getRouterContract = () => {
    const web3 =
      web3wallet ||
      new Web3(
        "https://bsc-dataseed3.defibit.io"
      );
    return new web3.eth.Contract(routerABI, swapRouter);
  };

  const getAmountOut = async (amountIn, tokenIn, tokenOut) => {
    if (
      (tokenIn === "WBNB" && tokenOut === "BNB") ||
      (tokenIn === "BNB" && tokenOut === "WBNB")
    )
      return amountIn;

    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenOutAddr = swapTokens[tokenOut].address;
    const routerContract = getRouterContract(tokenOut);

    amountIn = new BigNumber(amountIn * 10 ** 18).toFixed();
    try {
      const result = await routerContract.methods
        .getAmountsOut(amountIn, [tokenInAddr, tokenOutAddr])
        .call();
      
      return new BigNumber(result[1])
        .dividedBy(10 ** 18)
        .toFixed();
    } catch {
      return 0;
    }
  };

  const getAmountIn = async (amountOut, tokenIn, tokenOut) => {
    if (
      (tokenIn === "WBNB" && tokenOut === "BNB") ||
      (tokenIn === "BNB" && tokenOut === "WBNB")
    ) {
      return amountOut;
    }

    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenOutAddr = swapTokens[tokenOut].address;
    const routerContract = getRouterContract(tokenOut);

    amountOut = new BigNumber(amountOut * 10 ** 18).toFixed();

    try {
      const result = await routerContract.methods
        .getAmountsIn(amountOut, [tokenInAddr, tokenOutAddr])
        .call();
      return new BigNumber(result[0])
        .dividedBy(10 ** 18)
        .toFixed();
    } catch {
      return 0;
    }
  };

  const exchangeToken = async (amountIn, amountOutMin, tokenIn, tokenOut) => {
    let err = null;
    const deadline = Math.ceil(Date.now() / 1000) + parseInt(90);

    const routerContract = getRouterContract();

    amountIn = new BigNumber(amountIn * 10 ** 18).toFixed();
    amountOutMin = new BigNumber(amountOutMin * 10 ** 18).toFixed();

    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenOutAddr = swapTokens[tokenOut].address;
    const amountOut = new BigNumber(amountOutMin)
      .multipliedBy(100 - slippagePercentage)
      .dividedBy(100)
      .integerValue(BigNumber.ROUND_DOWN);

    if (tokenIn === "NIOB" || tokenOut === "NIOB") {
      return await exchangeAnchor(amountIn, amountOut, tokenIn, tokenOut, deadline);
    }

    let receipt;
    if (tokenIn === "BNB" && tokenOut === "WBNB") {
      const tokenContract = new web3wallet.eth.Contract(
        wbnbABI,
        tokenToAddress["WBNB"]
      );
      receipt = await tokenContract.methods
        .deposit()
        .send({ from: userAddress, value: amountIn })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else if (tokenIn === "WBNB" && tokenOut === "BNB") {
      const tokenContract = new web3wallet.eth.Contract(
        wbnbABI,
        tokenToAddress["WBNB"]
      );
      receipt = await tokenContract.methods
        .withdraw(amountIn)
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else if (isNativeToken(tokenIn)) {
      receipt = await routerContract.methods
        .swapExactETHForTokens(
          Number(amountOut).toString(),
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        )
        .send({ from: userAddress, value: amountIn })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else {
      receipt = await routerContract.methods
        .swapExactTokensForTokens(
          amountIn,
          '0',
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        )
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    }

    if (err === null)
      return { status: true, message: "success", txlink: receipt.transactionHash };
    else return { status: false, message: err.message, txlink: "" };
  };

  const exchangeAnchor = async (amountIn, amountOut, tokenIn, tokenOut, deadline) => {
    let err = null;
    const routerContract = getRouterContract();

    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenOutAddr = swapTokens[tokenOut].address;

    let receipt;
    if (isNativeToken(tokenIn)) {
      receipt = await routerContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(
          Number(amountOut).toString(),
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        )
        .send({ from: userAddress, value: amountIn })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else {
      receipt = await routerContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountIn,
          '0',
          [tokenInAddr, tokenOutAddr],
          userAddress,
          deadline
        )
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    }

    if (err === null)
      return { status: true, message: "success", txlink: receipt.transactionHash };
    else return { status: false, message: err.message, txlink: "" };
  };

  const getReserves = async (tokenA_addr, tokenB_addr) => {
    try {
      const web3 =
        web3wallet ||
        new Web3(
          "https://bsc-dataseed3.defibit.io"
        );
      const factoryContract = new web3.eth.Contract(
        exchangeFactoryABI,
        exchangeFactory
      );
      const pairAddress = await factoryContract.methods
        .getPair(tokenA_addr, tokenB_addr)
        .call();
      const pairContract = new web3.eth.Contract(exchangePairABI, pairAddress);
      const reserves = await pairContract.methods.getReserves().call();
      const token0 = await pairContract.methods.token0().call();
      if (tokenA_addr === token0)
        return [reserves._reserve0, reserves._reserve1];
      return [reserves._reserve1, reserves._reserve0];
    } catch (error) {
      return error;
    }
  };

  const getOtherTokenValue = async (
    tokenA,
    tokenB,
    enteredFirstValue,
    amount
  ) => {
    if (amount.toString() === "0") return 0;
    
    const tokenA_addr = swapTokens[tokenA].address;
    const tokenB_addr = swapTokens[tokenB].address;
    
    const reserves = await getReserves(tokenA_addr, tokenB_addr);
    let amountOut;

    if (enteredFirstValue) {
      amountOut = (
        amount *
        (reserves[1] / 10 ** 18 / (reserves[0] / 10 ** 18))
      ).toFixed(5);
    } else {
      amountOut = (
        amount *
        (reserves[0] / 10 ** 18 / (reserves[1] / 10 ** 18))
      ).toFixed(5);
    }

    return amountOut;
  };

  const addLiquidity = async (
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired
  ) => {
    let err = null;
    const deadline = Math.ceil(Date.now() / 1000) + parseInt(90);

    const routerContract = getRouterContract();

    const tokenA_addr = swapTokens[tokenA].address;
    const tokenB_addr = swapTokens[tokenB].address;

    let receipt;
    if (isNativeToken(tokenA) || isNativeToken(tokenB)) {
      let token_addr;
      let amountTokenDesired;
      let amountTokenMin;
      let amountETHMin;
      let value;

      if (isNativeToken(tokenA)) {
        token_addr = tokenB_addr;
        amountTokenDesired = amountBDesired;
        amountTokenMin = Math.floor(
          amountTokenDesired - (amountTokenDesired * slippagePercentage) / 100
        );
        amountETHMin = Math.floor(
          amountADesired - (amountADesired * slippagePercentage) / 100
        );
        value = amountADesired;
      } else {
        token_addr = tokenA_addr;
        amountTokenDesired = amountADesired;
        amountTokenMin = Math.floor(
          amountTokenDesired - (amountTokenDesired * slippagePercentage) / 100
        );
        amountETHMin = Math.floor(
          amountBDesired - (amountBDesired * slippagePercentage) / 100
        );
        value = amountBDesired;
      }

      amountTokenDesired = new BigNumber(amountTokenDesired * 10 ** 18).toFixed();
      amountTokenMin = new BigNumber(amountTokenMin * 10 ** 18).toFixed();
      amountETHMin = new BigNumber(amountETHMin * 10 ** 18).toFixed();
      value = new BigNumber(value * 10 ** 18).toString();

      receipt = await routerContract.methods
        .addLiquidityETH(
          token_addr,
          amountTokenDesired,
          amountTokenMin,
          amountETHMin,
          userAddress,
          deadline
        )
        .send({ from: userAddress, value })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else {
      let amountAMin = Math.floor(
        amountADesired - (amountADesired * slippagePercentage) / 100
      );
      let amountBMin = Math.floor(
        amountBDesired - (amountBDesired * slippagePercentage) / 100
      );

      amountADesired = new BigNumber(amountADesired * 10 ** 18).toFixed();
      amountBDesired = new BigNumber(amountBDesired * 10 ** 18).toFixed();
      amountAMin = new BigNumber(amountAMin * 10 ** 18).toFixed();
      amountBMin = new BigNumber(amountBMin * 10 ** 18).toFixed();

      receipt = await routerContract.methods
        .addLiquidity(
          tokenA_addr,
          tokenB_addr,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          userAddress,
          deadline
        )
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    }

    if (err === null){
      saveCacheUserLP(tokenA, tokenB);
      return { status: true, message: "success", txlink: receipt.transactionHash };
    }
    else return { status: false, message: err.message, txlink: "" };
  };

  const removeLiquidity = async (
    tokenA,
    tokenB,
    liquidityAmount,
    amountADesired,
    amountBDesired
  ) => {
    let err = null;
    const deadline = Math.ceil(Date.now() / 1000) + parseInt(90);

    const routerContract = getRouterContract();

    const tokenA_addr = swapTokens[tokenA].address;
    const tokenB_addr = swapTokens[tokenB].address;

    let receipt;
    if (isNativeToken(tokenA) || isNativeToken(tokenB)) {
      let token_addr;
      let amountTokenDesired;
      let amountTokenMin;
      let amountETHMin;

      if (isNativeToken(tokenA)) {
        token_addr = tokenB_addr;
        amountTokenDesired = amountBDesired;
        amountTokenMin = Math.floor(
          amountTokenDesired - (amountTokenDesired * slippagePercentage) / 100
        );
        amountETHMin = Math.floor(
          amountADesired - (amountADesired * slippagePercentage) / 100
        );
      } else {
        token_addr = tokenA_addr;
        amountTokenDesired = amountADesired;
        amountTokenMin = Math.floor(
          amountTokenDesired - (amountTokenDesired * slippagePercentage) / 100
        );
        amountETHMin = Math.floor(
          amountBDesired - (amountBDesired * slippagePercentage) / 100
        );
      }

      amountTokenDesired = new BigNumber(amountTokenDesired * 10 ** 18).toFixed();
      amountTokenMin = new BigNumber(amountTokenMin * 10 ** 18).toFixed();
      amountETHMin = new BigNumber(amountETHMin * 10 ** 18).toFixed();

      receipt = await routerContract.methods
        .removeLiquidityETH(
          token_addr,
          liquidityAmount,
          amountTokenMin,
          amountETHMin,
          userAddress,
          deadline,
        )
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    } else {
      let amountAMin = Math.floor(
        amountADesired - (amountADesired * slippagePercentage) / 100
      );
      let amountBMin = Math.floor(
        amountBDesired - (amountBDesired * slippagePercentage) / 100
      );

      amountADesired = new BigNumber(amountADesired * 10 ** 18).toFixed();
      amountBDesired = new BigNumber(amountBDesired * 10 ** 18).toFixed();
      amountAMin = new BigNumber(amountAMin * 10 ** 18).toFixed();
      amountBMin = new BigNumber(amountBMin * 10 ** 18).toFixed();

      receipt = await routerContract.methods
        .removeLiquidity(
          tokenA_addr,
          tokenB_addr,
          liquidityAmount,
          amountAMin,
          amountBMin,
          userAddress,
          deadline,
        )
        .send({ from: userAddress })
        .catch((error) => {
          err = error;
          console.log(error.message);
          return { status: false, message: err.message, txlink: "" };
        });
    }

    if (err === null)
      return { status: true, message: "success", txlink: receipt.transactionHash };
    else return { status: false, message: err.message, txlink: "" };
  };

  const approvePair = async (pairAddress) => {
    const pairContract = new web3wallet.eth.Contract(exchangePairABI, pairAddress);

    let err = null;
    const receipt = await pairContract.methods
      .approve(
        swapRouter,
        "90000000000000000000000000000000000000000000000000000000000000000000000"
      )
      .send({ from: userAddress })
      .catch((error) => {
        err = error;
        console.log(error.message);
        return { status: false, message: err.message, txlink: "" };
      });

    if (err === null)
      return { status: true, message: "success", txlink: receipt.transactionHash };
    else return { status: false, message: err.message, txlink: "" };
  };

  const isPairApproved = async (pairAddress) => {
    const pairContract = new web3wallet.eth.Contract(exchangePairABI, pairAddress);
    const result = await pairContract.methods
      .allowance(userAddress, swapRouter)
      .call()
      .catch((error) => {
        console.log(error.message);
        return false;
      });
    return result > "1000000000000000000000000";
  };

  const approveRouter = async (tokenIn) => {
    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenContract = new web3wallet.eth.Contract(erc20ABI, tokenInAddr);

    let err = null;
    const receipt = await tokenContract.methods
      .approve(
        swapRouter,
        "90000000000000000000000000000000000000000000000000000000000000000000000"
      )
      .send({ from: userAddress })
      .catch((error) => {
        err = error;
        console.log(error.message);
        return { status: false, message: err.message, txlink: "" };
      });

    if (err === null)
      return { status: true, message: "success", txlink: receipt.transactionHash };
    else return { status: false, message: err.message, txlink: "" };
  };

  const isRouterApproved = async (tokenIn) => {
    if (isNativeToken(tokenIn)) return true;
    if (!swapTokens[tokenIn]) return false;

    const tokenInAddr = swapTokens[tokenIn].address;
    const tokenContract = new web3wallet.eth.Contract(erc20ABI, tokenInAddr);
    const result = await tokenContract.methods
      .allowance(userAddress, swapRouter)
      .call()
      .catch((error) => {
        console.log(error.message);
        return false;
      });
    return result > "1000000000000000000000000";
  };

  const getBNBBalance = async () => {
    const result = await web3wallet.eth
      .getBalance(userAddress)
      .catch((error) => {
        console.log(error.message);
        return 0;
      });
    return new BigNumber(result)
      .dividedBy(10 ** 18)
      .toFixed();
  };

  const getTokenBalance = async (token, inWei = false) => {
    if (isNativeToken(token)) {
      return await getBNBBalance();
    }

    const tokenAddr = swapTokens[token].address;
    const tokenContract = new web3wallet.eth.Contract(erc20ABI, tokenAddr);
    const result = await tokenContract.methods
      .balanceOf(userAddress)
      .call()
      .catch((error) => {
        console.log(error.message);
        return 0;
      });
    if (inWei) return result;
    
    return new BigNumber(result)
      .dividedBy(10 ** 18)
      .toFixed();
  };

  const defaultLPs = [["NIOB", "BNB"], ["NIOB", "USDC"], ['WBNB', "USDC"]];

  const getCachedUserLPs = async () => {
    let lps;
    if(!userAddress) lps = defaultLPs;
    var cachedLPs = JSON.parse(localStorage.getItem('cachedLPs'));
    if(!cachedLPs || !cachedLPs[userAddress]) lps = defaultLPs;
    else lps = cachedLPs[userAddress]
    
    const calls =[];
    for(let i=0; i< lps.length; i++){
      const tokenA = lps[i][0];
      const tokenB = lps[i][1];
      if(swapTokens[tokenA] && swapTokens[tokenB])
        calls.push(getLPBalance(tokenA, tokenB));
    }
    const result = await Promise.all(calls);
    return result.filter((r) => r && parseFloat(r.amount) > 0);
  }

  const isArrayInArray = (arr, item) => {
    const item_as_string = JSON.stringify(item);
    
    return arr.some(function(ele){
      return JSON.stringify(ele) === item_as_string;
    });
  }

  const saveCacheUserLP = (tokenA, tokenB) => {
    if(!userAddress) return;
    var cachedLPs = JSON.parse(localStorage.getItem('cachedLPs'));
    if(!cachedLPs) cachedLPs = {};
    if(!cachedLPs[userAddress]) cachedLPs[userAddress] = defaultLPs;
    if(isArrayInArray(cachedLPs[userAddress], [tokenA, tokenB]) || isArrayInArray(cachedLPs[userAddress], [tokenB, tokenA]))
      return;
    
    cachedLPs[userAddress].push([tokenA, tokenB]);

    localStorage.setItem('cachedLPs',JSON.stringify(cachedLPs));
  }

  const getLPBalance = async (tokenA, tokenB) => {
    const tokenA_addr = swapTokens[tokenA].address;
    const tokenB_addr = swapTokens[tokenB].address;


    const factoryContract = new web3wallet.eth.Contract(
      exchangeFactoryABI,
      exchangeFactory
    );
    const pairAddress = await factoryContract.methods
      .getPair(tokenA_addr, tokenB_addr)
      .call();

    if(pairAddress === '0x0000000000000000000000000000000000000000') return;

    const pairContract = new web3wallet.eth.Contract(exchangePairABI, pairAddress);

    const [balance, totalSupply, reservesObj, token0] = await Promise.all([
      pairContract.methods.balanceOf(userAddress).call(),
      pairContract.methods.totalSupply().call(),
      pairContract.methods.getReserves().call(),
      pairContract.methods.token0().call()
    ]);

    let reserveA = (tokenA_addr === token0) ? reservesObj._reserve0 : reservesObj._reserve1;
    let reserveB = (tokenA_addr === token0) ? reservesObj._reserve1 : reservesObj._reserve0;

    reserveA = new BigNumber(reserveA.toString())
    .dividedBy(10 ** 18)
    .toFixed(2);

    reserveB = new BigNumber(reserveB.toString())
    .dividedBy(10 ** 18)
    .toFixed(2);
    
    const amount = new BigNumber(balance)
      .dividedBy(10 ** 18)
      .toFixed(2);

    const share = new BigNumber(balance).dividedBy(totalSupply).multipliedBy(100).toFixed(2);
    
    return {amount, amountWei: balance.toString(), share, tokenA, tokenB, reserveA, reserveB, pairAddress};
  }

  const getTokensList = () => {
    return Object.keys(swapTokens);
  };

  return {
    getAmountOut,
    getAmountIn,
    exchangeToken,
    approveRouter,
    isRouterApproved,
    getBNBBalance,
    getTokenBalance,
    getTokensList,
    addLiquidity,
    getReserves,
    getOtherTokenValue,
    removeLiquidity,
    getLPBalance,
    saveCacheUserLP,
    getCachedUserLPs,
    approvePair,
    isPairApproved
  };
};

export default useSwap;
